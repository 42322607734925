import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import { overwriteBaseColors } from './lib/frontend';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'material-icons/iconfont/material-icons.css';
import 'material-symbols';
import './assets/scss/index.scss';
import 'primeicons/primeicons.css'
import 'primevue/resources/primevue.min.css'
import "primevue/resources/themes/lara-light-blue/theme.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import PrimeVue from 'primevue/config';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import InputMask from 'primevue/inputmask';
import InputText from 'primevue/inputtext';
import Slider from 'primevue/slider';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import ColorPicker from 'primevue/colorpicker';

import i18n from '@/locale/index';
import {
    faBars,
    faBell,
    faCalendar,
    faHomeLgAlt,
    faUser,
    faUserSecret,
    faChevronRight,
    faChevronUp,
    faSearch,
    faFilter,
    faMagnifyingGlass,
    faEye,
    faEyeSlash,
    faFaceFrown,
    faUserCheck,
    faChartSimple,
    faNewspaper,
    faHouse,
    faArrowRightFromBracket
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faUserSecret,
    faUser,
    faBars,
    faBell,
    faCalendar,
    faHomeLgAlt,
    faChevronRight,
    faChevronUp,
    faSearch,
    faFilter,
    faMagnifyingGlass,
    faEye,
    faEyeSlash,
    faFaceFrown,
    faUserCheck,
    faChartSimple,
    faNewspaper,
    faHouse,
    faArrowRightFromBracket
);

overwriteBaseColors('#730DD9');

createApp(App)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component('VueDatePicker', VueDatePicker)
    .component('Dropdown', Dropdown)
    .component('InputNumber', InputNumber)
    .component('Calendar', Calendar)
    .component('Checkbox', Checkbox)
    .component('InputMask', InputMask)
    .component('InputText', InputText)
    .component('InputGroup', InputGroup)
    .component('Slider', Slider)
    .component('InputGroupAddon', InputGroupAddon)
    .component('InputGroup', InputGroup)
    .component('DataTable', DataTable)
    .component('Column', Column)
    // .component('ColorPicker', ColorPicker)
    .use(router)
    .use(i18n)
    .use(PrimeVue)
    .use(store)
    .mount('#app')

